/* Reset and Base Styles */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(180deg, #2b1d4a, #3d2e6b);
  color: #f0e6d2;
  min-height: 100vh;
  overflow-x: hidden;
}

.app {
  max-width: 1400px;
  margin: 0 auto;
  padding: 30px;
  position: relative;
  z-index: 1;
}

.starry-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}

.rotating-text-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 6rem;
  font-weight: 700;
  color: rgba(212, 175, 55, 0.2);
  text-transform: uppercase;
  animation: rotateGlow 20s linear infinite;
  pointer-events: none;
  z-index: -1;
}

@keyframes rotateGlow {
  0% { transform: translate(-50%, -50%) rotate(0deg); opacity: 0.2; }
  50% { opacity: 0.4; }
  100% { transform: translate(-50%, -50%) rotate(360deg); opacity: 0.2; }
}

/* Header */
.header {
  text-align: center;
  padding: 20px 15px 40px 15px; /* Increased bottom padding for menu space */
  background: linear-gradient(135deg, #4a2e85, #8a5db5, #d4af37);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.header::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 0%, transparent 70%);
  animation: glow 8s infinite ease-in-out;
  pointer-events: none;
  z-index: 0;
  overflow: hidden; /* Contain glow effect */
}

@keyframes glow {
  0%, 100% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.1); opacity: 0.8; }
}

.title {
  font-size: 2.5rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #d4af37;
  text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
  margin: 0;
  position: relative;
  z-index: 1;
  transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
}

.letter {
  display: inline-block;
  transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden;
}

.subtitle {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 10px 0 15px;
  color: #e6d8a7;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  position: relative;
  z-index: 1;
}

.auth-section {
  margin-top: 15px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
}

.auth-btn {
  padding: 14px 28px;
  background: linear-gradient(45deg, #8a5db5, #d4af37);
  color: #2b1d4a;
  border: none;
  border-radius: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 0 15px rgba(212, 175, 55, 0.7), 0 0 20px rgba(138, 93, 181, 0.5);
  font-size: 1.1rem;
}

.auth-btn:hover, .auth-btn:active {
  transform: translateY(-3px);
  box-shadow: 0 0 20px rgba(212, 175, 55, 0.9), 0 0 30px rgba(138, 93, 181, 0.7);
}

/* Navbar */
.navbar {
  display: flex;
  gap: 10px;
  margin: 10px 0;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 50; /* Increased from 10 to ensure it’s above all content */
}

/* Desktop Navigation */
.desktop-nav {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

/* Mobile Navigation */
.mobile-nav {
  display: none; /* Hidden by default */
}

.nav-menu-btn {
  padding: 12px 24px;
  background: linear-gradient(45deg, #8a5db5, #d4af37);
  color: #2b1d4a;
  border: none;
  border-radius: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 0 15px rgba(212, 175, 55, 0.7), 0 0 20px rgba(138, 93, 181, 0.5);
  font-size: 1.1rem;
  width: 100%;
  max-width: 200px;
}

.nav-menu-btn:hover,
.nav-menu-btn:active {
  transform: translateY(-3px);
  box-shadow: 0 0 20px rgba(212, 175, 55, 0.9), 0 0 30px rgba(138, 93, 181, 0.7);
}

.nav-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 15px;
  width: 90%;
  max-width: 250px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1000; /* Increased from 20 to ensure it’s above featured section */
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 5px;
}

.nav-menu-item {
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.1);
  color: #e6d8a7;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  text-align: center;
}

.nav-menu-item:hover,
.nav-menu-item.active {
  background: linear-gradient(45deg, #8a5db5, #d4af37);
  color: #2b1d4a;
}

/* Auth Modal */
.auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(43, 29, 74, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.auth-content {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 20px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
}

.auth-title {
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
  text-align: center;
}

.auth-tabs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-btn {
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  color: #e6d8a7;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.tab-btn.active {
  background: linear-gradient(45deg, #8a5db5, #d4af37);
  color: #2b1d4a;
}

.tab-btn:hover:not(.active) {
  background: rgba(255, 255, 255, 0.2);
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.auth-form input {
  padding: 12px;
  border-radius: 12px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #f0e6d2;
  font-size: 1rem;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.auth-form input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 15px rgba(212, 175, 55, 0.3);
}

.submit-btn {
  padding: 12px;
  background: linear-gradient(45deg, #4a2e85, #8a5db5);
  color: #f0e6d2;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.submit-btn:hover, .submit-btn:active {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(138, 93, 181, 0.5);
}

.close-btn {
  padding: 10px 20px;
  background: linear-gradient(45deg, #8a5db5, #d4af37);
  color: #2b1d4a;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  display: block;
  margin: 20px auto 0;
}

.close-btn:hover, .close-btn:active {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(212, 175, 55, 0.5);
}

/* Featured Section */
.featured-section {
  margin-bottom: 30px;
  text-align: center;
  z-index: 2;
}

.featured-title {
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.featured-video {
  background: rgba(43, 29, 74, 0.1); /* Adjusted from rgba(255, 255, 255, 0.1) */
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.featured-video h3 {
  font-size: 1.6rem;
  color: #d4af37;
  margin: 10px 0;
}

.featured-video p {
  font-size: 1rem;
  color: #e6d8a7;
  margin: 5px 0;
}

/* Landing Section */
.landing-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
  z-index: 2;
}

.landing-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.landing-text {
  font-size: 1.4rem;
  color: #e6d8a7;
  margin: 0 0 20px;
  line-height: 1.6;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.cta-btn {
  padding: 18px 36px;
  font-size: 1.5rem;
  background: linear-gradient(45deg, #d4af37, #8a5db5);
  color: #2b1d4a;
  border: none;
  border-radius: 15px;
  font-weight: 700;
  cursor: pointer;
  transition: transform 0.2s;
  margin: 15px;
  box-shadow: 0 0 15px rgba(212, 175, 55, 0.7), 0 0 20px rgba(138, 93, 181, 0.5);
  animation: pulseGlow 2s infinite ease-in-out !important;
}

@keyframes pulseGlow {
  0%, 100% { box-shadow: 0 0 15px rgba(212, 175, 55, 0.7), 0 0 20px rgba(138, 93, 181, 0.5); }
  50% { box-shadow: 0 0 25px rgba(212, 175, 55, 0.9), 0 0 30px rgba(138, 93, 181, 0.7); }
}

.cta-btn:hover, .cta-btn:active {
  transform: translateY(-3px);
  box-shadow: 0 0 20px rgba(212, 175, 55, 0.9), 0 0 30px rgba(138, 93, 181, 0.7);
}

.landing-disclaimer {
  font-size: 1rem;
  color: #b8a9d1;
  font-style: italic;
  margin-top: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Why CLO2 Section */
.why-clo2-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
  z-index: 2;
}

.why-clo2-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.why-clo2-text {
  font-size: 1.4rem;
  color: #e6d8a7;
  margin: 0 0 20px;
  line-height: 1.6;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Grenon Section */
.grenon-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
  z-index: 2;
  position: relative;
}

.grenon-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(212, 175, 55, 0.2) 0%, transparent 70%);
  animation: glow 8s infinite ease-in-out;
  pointer-events: none;
  z-index: -1;
}

.grenon-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
}

.grenon-text {
  font-size: 1.4rem;
  color: #e6d8a7;
  margin: 0 auto 20px;
  line-height: 1.6;
  max-width: 800px;
}

.grenon-gallery {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  gap: 15px;
  padding: 20px 0;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: rgba(212, 175, 55, 0.5) rgba(255, 255, 255, 0.1);
  white-space: nowrap;
}

.grenon-gallery::-webkit-scrollbar {
  height: 8px;
}

.grenon-gallery::-webkit-scrollbar-thumb {
  background: rgba(212, 175, 55, 0.5);
  border-radius: 4px;
}

.grenon-gallery::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.grenon-gallery-image {
  flex: 0 0 auto;
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  border: 2px solid rgba(212, 175, 55, 0.5);
  box-shadow: 0 4px 15px rgba(212, 175, 55, 0.4);
  scroll-snap-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grenon-gallery-image:hover,
.grenon-gallery-image:active {
  transform: scale(1.05);
  box-shadow: 0 6px 25px rgba(212, 175, 55, 0.7);
}

.grenon-book {
  margin-top: 20px;
}

.grenon-timeline {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 15px;
  padding: 20px 0;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: rgba(212, 175, 55, 0.5) rgba(255, 255, 255, 0.1);
}

.grenon-timeline::-webkit-scrollbar {
  height: 8px;
}

.grenon-timeline::-webkit-scrollbar-thumb {
  background: rgba(212, 175, 55, 0.5);
  border-radius: 4px;
}

.grenon-timeline::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.timeline-moment {
  flex: 0 0 auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  scroll-snap-align: center;
  min-width: 120px;
  text-align: center;
}

.timeline-moment:hover, .timeline-moment.active {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(212, 175, 55, 0.7);
}

.timeline-year {
  font-size: 1.2rem;
  color: #d4af37;
  font-weight: 700;
}

.timeline-title {
  font-size: 1rem;
  color: #e6d8a7;
}

.timeline-tooltip {
  position: absolute;
  background: linear-gradient(45deg, #8a5db5, #d4af37);
  color: #2b1d4a;
  padding: 10px;
  border-radius: 8px;
  font-size: 0.9rem;
  margin-top: 10px;
  z-index: 20;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 10px);
}

/* Book Menu Styles */
.book-menu-container {
  position: relative;
  display: inline-block;
  text-align: center;
}

.book-menu-btn {
  width: 100%;
  max-width: 300px;
  padding: 14px 28px;
  background: linear-gradient(45deg, #8a5db5, #d4af37);
  color: #2b1d4a;
  border: none;
  border-radius: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 0 15px rgba(212, 175, 55, 0.7), 0 0 20px rgba(138, 93, 181, 0.5);
}

.book-menu-btn:hover, .book-menu-btn:active {
  transform: translateY(-3px);
  box-shadow: 0 0 20px rgba(212, 175, 55, 0.9), 0 0 30px rgba(138, 93, 181, 0.7);
}

.book-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 100;
  text-align: center;
  margin-top: 10px;
}

.book-menu-title {
  font-size: 1.6rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
}

.book-menu-item {
  display: block;
  width: 100%;
  margin: 10px auto;
  padding: 14px 28px;
  font-size: 1.2rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.book-menu-item:hover {
  background: linear-gradient(45deg, #8a5db5, #d4af37);
  color: #2b1d4a;
  box-shadow: 0 4px 15px rgba(212, 175, 55, 0.5);
}

.book-link {
  color: #e6d8a7;
  text-decoration: none;
  font-weight: 600;
}

.book-link:hover {
  color: #2b1d4a;
}

.book-description {
  font-size: 1rem;
  color: #e6d8a7;
  margin: 5px 0 15px;
  line-height: 1.4;
}

/* Enlarged Image Overlay */
.enlarged-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.enlarged-image {
  max-width: 90%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 10px;
  border: 3px solid rgba(212, 175, 55, 0.7);
  box-shadow: 0 8px 30px rgba(212, 175, 55, 0.5);
}

/* A Message from Bob The Plumber Section */
.bob-message-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
  z-index: 2;
  position: relative;
}

.bob-message-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(212, 175, 55, 0.2) 0%, transparent 70%);
  animation: glow 8s infinite ease-in-out;
  pointer-events: none;
  z-index: -1;
}

.bob-message-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
}

.bob-message-text {
  font-size: 1.4rem;
  color: #e6d8a7;
  margin: 0 auto 20px;
  line-height: 1.6;
  max-width: 800px;
}

/* Testimonials Section */
.testimonials-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
  z-index: 2;
}

.testimonials-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 8px;
}

.testimonial-text {
  color: #ddd;
  font-style: italic;
}

.testimonial-author {
  color: #fff;
  font-weight: bold;
  margin-top: 0.5rem;
}

/* FAQ Section */
.faq-section {
  text-align: left;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
  z-index: 2;
}

.faq-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  text-align: center;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq-item {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 15px;
}

.faq-question {
  font-size: 1.6rem;
  font-weight: 600;
  color: #d4af37;
  margin-bottom: 10px;
}

.faq-answer {
  font-size: 1.2rem;
  color: #e6d8a7;
  line-height: 1.6;
}

/* History Modal */
.history-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(43, 29, 74, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.history-content {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  max-height: 80vh;
  overflow-y: auto;
}

.history-title {
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
  text-align: center;
}

.history-text {
  font-size: 1.2rem;
  color: #e6d8a7;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Course Modal */
.course-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(43, 29, 74, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.course-content {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  max-height: 80vh;
  overflow-y: auto;
}

.course-title {
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
  text-align: center;
}

.course-text {
  font-size: 1.2rem;
  color: #e6d8a7;
  line-height: 1.6;
  margin-bottom: 20px;
}

.course-text a {
  color: #d4af37;
  text-decoration: underline;
}

.course-text a:hover {
  color: #8a5db5;
}

.course-list {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
  color: #e6d8a7;
}

.course-list li {
  margin-bottom: 10px;
}

.course-list strong {
  color: #d4af37;
}

/* Main Content */
.main {
  padding: 0 20px;
  z-index: 2;
}

.upload-form {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.upload-form input {
  padding: 12px;
  border-radius: 12px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #f0e6d2;
  font-size: 1rem;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  width: 100%;
  max-width: 400px;
}

.upload-form input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 15px rgba(212, 175, 55, 0.3);
}

.upload-btn {
  padding: 12px 24px;
  background: linear-gradient(45deg, #4a2e85, #8a5db5);
  color: #f0e6d2;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.upload-btn:hover, .upload-btn:active {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(138, 93, 181, 0.5);
}

.progress-container {
  width: 100%;
  max-width: 300px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 15px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #8a5db5, #d4af37);
  transition: width 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-text {
  font-size: 0.9rem;
  font-weight: 600;
  color: #2b1d4a;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

/* Search Bar */
.search-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.search-input {
  padding: 12px;
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #f0e6d2;
  font-size: 1rem;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 15px rgba(212, 175, 55, 0.3);
}

/* Video Grid */
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 25px;
}

.video-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  cursor: pointer;
}

.video-card:hover, .video-card:active {
  transform: scale(1.03);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
}

.video-card::before {
  content: '▶';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: #d4af37;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.video-card:hover::before, .video-card:active::before {
  opacity: 0.8;
}

.video-title {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 15px;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.video-description {
  font-size: 1rem;
  margin: 0 15px 15px;
  color: #e6d8a7;
}

.video-uploader {
  font-size: 0.9rem;
  margin: 0 15px 15px;
  color: #b8a9d1;
}

.video-views {
  font-size: 0.9rem;
  margin: 0 15px 15px;
  color: #b8a9d1;
}

.like-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 15px;
}

.like-btn {
  padding: 8px 16px;
  background: linear-gradient(45deg, #8a5db5, #d4af37);
  color: #2b1d4a;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s, background 0.3s;
}

.like-btn:hover:not(.liked):not(:disabled), .like-btn:active:not(.liked):not(:disabled) {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(212, 175, 55, 0.5);
}

.like-btn.liked, .like-btn:disabled {
  background: linear-gradient(45deg, #5a3d7a, #a88b2d);
  cursor: not-allowed;
  box-shadow: none;
}

.like-count {
  font-size: 0.9rem;
  color: #e6d8a7;
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid #d4af37;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite, glowPulse 2s infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes glowPulse {
  0%, 100% { box-shadow: 0 0 10px rgba(212, 175, 55, 0.5); }
  50% { box-shadow: 0 0 20px rgba(212, 175, 55, 1); }
}

.no-videos {
  text-align: center;
  font-size: 1.8rem;
  color: #d4af37;
  text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
}

/* Footer */
.footer {
  text-align: center;
  padding: 20px 15px;
  background: linear-gradient(135deg, #4a2e85, #8a5db5, #d4af37);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-top: 30px;
  position: relative;
  z-index: 2;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.footer-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #e6d8a7;
  text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
  margin: 0 0 10px;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-icon {
  font-size: 1.8rem;
  color: #d4af37;
  text-decoration: none;
  transition: transform 0.2s, text-shadow 0.2s;
}

.social-icon:hover, .social-icon:active {
  transform: scale(1.2);
  text-shadow: 0 0 15px rgba(212, 175, 55, 0.9), 0 0 20px rgba(138, 93, 181, 0.7);
}

/* Back to Top Button */
.back-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 20px;
  background: linear-gradient(45deg, #8a5db5, #d4af37);
  color: #2b1d4a;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 0 15px rgba(212, 175, 55, 0.7), 0 0 20px rgba(138, 93, 181, 0.5);
  z-index: 1000;
}

.back-to-top-btn:hover, .back-to-top-btn:active {
  transform: translateY(-3px);
  box-shadow: 0 0 20px rgba(212, 175, 55, 0.9), 0 0 30px rgba(138, 93, 181, 0.7);
}

/* Article Section (A Message from Bob The Plumber) */
.article-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
  z-index: 2;
  position: relative;
}

.article-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(212, 175, 55, 0.2) 0%, transparent 70%);
  animation: glow 8s infinite ease-in-out;
  pointer-events: none;
  z-index: -1;
}

.article-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
}

.article-content {
  max-width: 800px;
  margin: 0 auto;
}

.article-text {
  font-size: 1.4rem;
  color: #e6d8a7;
  margin: 0 0 20px;
  line-height: 1.6;
}

.article-author {
  font-size: 1.2rem;
  color: #b8a9d1;
  font-style: italic;
  margin-top: 20px;
}

.nav-btn {
  background: #d4af37;
  color: #1a1a1a;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background 0.3s;
}

.nav-btn:hover {
  background: #b8932e;
}

/* Add to App.css */
.video-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.rumble-video {
  width: 100%;
  max-width: 640px;
  height: 360px;
  border: 2px solid #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

/* Update existing article styles in App.css */
.articles-section {
  text-align: center;
  padding: 40px 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
  z-index: 2;
  position: relative;
}

.articles-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(212, 175, 55, 0.2) 0%, transparent 70%);
  animation: glow 8s infinite ease-in-out;
  pointer-events: none;
  z-index: -1;
}

.article {
  margin-bottom: 2rem;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.article-title {
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(90deg, #d4af37, #8a5db5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
  text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
}

.article-author {
  font-size: 1.2rem;
  color: #b8a9d1;
  font-style: italic;
  margin-bottom: 15px;
}

.article-content {
  max-width: 100%;
}

/* Quadrant Layout */
.quadrants-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 20px;
  padding: 20px 0;
  max-width: 1400px;
  margin: 0 auto;
}

.quadrant {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.quadrant.featured-section,
.quadrant.landing-section,
.quadrant.why-clo2-section,
.quadrant.article-section,
.quadrant.testimonials-section {
  margin-bottom: 0;
}

.quadrant .featured-video {
  max-width: 100%;
  padding: 10px;
}

.quadrant .button-group {
  flex-wrap: wrap;
  gap: 10px;
}

.quadrant .cta-btn {
  padding: 12px 24px;
  font-size: 1.2rem;
  margin: 5px;
}

/* Add to App.css */
.button-group {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .desktop-nav {
    display: none; /* Hide desktop buttons */
  }

  .mobile-nav {
    display: block; /* Show mobile menu */
    width: 100%;
    text-align: center;
  }

  .navbar {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .app { padding: 15px; }
  .header { 
    padding: 15px 10px 40px 10px; /* Increased bottom padding from 30px */
    margin-bottom: 15px; 
    max-width: 700px;
  }
  .title { font-size: 2rem; }
  .subtitle { font-size: 1rem; }
  .auth-section { margin-top: 10px; padding: 5px; gap: 8px; }
  .auth-btn { 
    padding: 12px 24px;
    font-size: 1rem; 
    box-shadow: 0 0 12px rgba(212, 175, 55, 0.7), 0 0 18px rgba(138, 93, 181, 0.5); 
  }
  .auth-btn:hover, .auth-btn:active {
    box-shadow: 0 0 18px rgba(212, 175, 55, 0.9), 0 0 25px rgba(138, 93, 181, 0.7);
  }
  .search-input { max-width: 300px; }
  .featured-section { margin-bottom: 20px; }
  .featured-title { font-size: 1.6rem; }
  .featured-video { padding: 15px; }
  .featured-video > div { height: 25vh !important; }
  .landing-section, .why-clo2-section, .grenon-section, .bob-message-section, .testimonials-section, .faq-section { padding: 20px 10px; margin-bottom: 20px; }
  .landing-title, .why-clo2-title, .grenon-title, .bob-message-title, .testimonials-title, .faq-title { font-size: 1.8rem; }
  .landing-text, .why-clo2-text, .grenon-text, .bob-message-text, .testimonial-text, .faq-answer { font-size: 1rem; }
  .cta-btn { 
    padding: 14px 28px;
    font-size: 1.2rem; 
    width: 80%; 
    margin: 10px auto; 
    display: block; 
  }
  .landing-disclaimer { font-size: 0.8rem; }
  .grenon-gallery-image { width: 250px; height: 166px; }
  .grenon-timeline { padding: 15px 0; }
  .timeline-moment { min-width: 100px; }
  .timeline-year { font-size: 1rem; }
  .timeline-title { font-size: 0.9rem; }
  .main { padding: 0 10px; }
  .upload-form { padding: 15px; margin-bottom: 20px; }
  .upload-btn { padding: 10px 20px; }
  .video-grid { grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); gap: 15px; }
  .video-title { font-size: 1.4rem; }
  .video-description { font-size: 0.9rem; }
  .video-uploader, .video-views { font-size: 0.8rem; }
  .loader { width: 40px; height: 40px; }
  .history-title, .course-title, .auth-title { font-size: 1.6rem; }
  .history-text, .course-text { font-size: 1rem; }
  .history-content, .course-content, .auth-content { padding: 15px; }
  .rotating-text-background { font-size: 4rem; }
  .auth-form input { padding: 10px; font-size: 0.9rem; }
  .submit-btn { padding: 10px; }
  .progress-container { max-width: 250px; }
  .progress-text { font-size: 0.8rem; }
  .testimonials-grid { grid-template-columns: 1fr; }
  .faq-question { font-size: 1.4rem; }
  .like-btn { padding: 6px 12px; }
  .like-count { font-size: 0.8rem; }
  .footer { 
    padding: 15px 10px; 
    margin-top: 20px; 
    max-width: 700px; 
  }
  .footer-text { font-size: 1rem; }
  .social-icon { font-size: 1.5rem; }
  .book-menu { width: 100%; max-width: 300px; }
  .book-menu-btn { padding: 12px 24px; font-size: 1rem; }
  .book-menu-item { padding: 12px 24px; font-size: 1rem; }
  .book-menu-title { font-size: 1.4rem; }
  .book-description { font-size: 0.9rem; }
  .back-to-top-btn { 
    padding: 10px 18px; 
    font-size: 0.9rem; 
  }
  .articles-section { padding: 20px 10px; margin-bottom: 20px; }
  .article { padding: 15px; margin-bottom: 1.5rem; }
  .article-title { font-size: 1.6rem; }
  .article-author { font-size: 1rem; }
  .article-text { font-size: 1rem; }
  .quadrants-container { 
    grid-template-columns: 1fr; 
    grid-template-rows: auto; 
    padding: 15px 0; 
  }
  .quadrant { padding: 15px; }
  .quadrant .cta-btn { width: 80%; margin: 10px auto; display: block; }
  .quadrant .featured-video > div { height: 25vh !important; }
  .button-group { flex-direction: column; align-items: center; }
  .rumble-video { max-width: 100%; height: 200px; }
}

@media (max-width: 480px) {
  .app { padding: 10px; }
  .header { 
    padding: 10px 8px 35px 8px; /* Increased bottom padding from 25px */
    max-width: 90%;
  }
  .title { font-size: 1.8rem; }
  .subtitle { font-size: 0.9rem; }
  .auth-section { margin-top: 8px; gap: 5px; }
  .auth-btn { 
    padding: 10px 20px;
    font-size: 0.9rem; 
    box-shadow: 0 0 10px rgba(212, 175, 55, 0.7), 0 0 15px rgba(138, 93, 181, 0.5); 
  }
  .auth-btn:hover, .auth-btn:active {
    box-shadow: 0 0 15px rgba(212, 175, 55, 0.9), 0 0 20px rgba(138, 93, 181, 0.7);
  }
  .search-input { max-width: 250px; }
  .featured-title { font-size: 1.4rem; }
  .featured-video > div { height: 25vh !important; }
  .landing-title, .why-clo2-title, .grenon-title, .bob-message-title, .testimonials-title, .faq-title { font-size: 1.5rem; }
  .landing-text, .why-clo2-text, .grenon-text, .bob-message-text, .testimonial-text, .faq-answer { font-size: 0.9rem; }
  .cta-btn { 
    padding: 12px 24px;
    font-size: 1.1rem; 
    width: 90%; 
  }
  .grenon-section, .bob-message-section { padding: 15px 8px; }
  .grenon-gallery-image { width: 200px; height: 133px; }
  .grenon-timeline { padding: 10px 0; }
  .timeline-moment { min-width: 80px; }
  .timeline-year { font-size: 0.9rem; }
  .timeline-title { font-size: 0.8rem; }
  .timeline-tooltip { font-size: 0.8rem; padding: 8px; }
  .video-grid { grid-template-columns: 1fr; gap: 10px; }
  .video-title { font-size: 1.2rem; }
  .video-description { font-size: 0.8rem; }
  .video-uploader, .video-views { font-size: 0.7rem; }
  .no-videos { font-size: 1.2rem; }
  .loader { width: 36px; height: 36px; }
  .history-title, .course-title, .auth-title { font-size: 1.4rem; }
  .history-text, .course-text { font-size: 0.9rem; }
  .close-btn { padding: 8px 16px; }
  .rotating-text-background { font-size: 3rem; }
  .auth-tabs { flex-direction: column; gap: 5px; }
  .tab-btn { padding: 6px 12px; }
  .progress-container { max-width: 200px; }
  .progress-text { font-size: 0.7rem; }
  .upload-form input { max-width: 100%; }
  .testimonials-grid { gap: 15px; }
  .faq-question { font-size: 1.2rem; }
  .like-btn { padding: 5px 10px; }
  .like-count { font-size: 0.7rem; }
  .footer { 
    padding: 10px 8px; 
    margin-top: 15px; 
    max-width: 90%; 
  }
  .footer-text { font-size: 0.9rem; }
  .social-icon { font-size: 1.3rem; }
  .book-menu { width: 100%; max-width: 250px; }
  .book-menu-btn { padding: 10px 20px; font-size: 0.9rem; }
  .book-menu-item { padding: 10px 20px; font-size: 0.9rem; }
  .book-menu-title { font-size: 1.2rem; }
  .book-description { font-size: 0.8rem; }
  .back-to-top-btn { 
    padding: 8px 16px; 
    font-size: 0.8rem; 
  }
  .articles-section { padding: 15px 8px; }
  .article { padding: 10px; }
  .article-title { font-size: 1.4rem; }
  .article-author { font-size: 0.9rem; }
  .article-text { font-size: 0.9rem; }
  .quadrants-container { padding: 10px 0; }
  .quadrant { padding: 10px; }
  .quadrant .cta-btn { padding: 10px 20px; font-size: 1rem; }
}

/* Keep desktop styles intact */
@media (min-width: 769px) {
  .mobile-nav {
    display: none; /* Hide mobile menu on desktop */
  }
}